'use client';

import { useEffect } from 'react';
import Button from './ui/components/button/Button';
import Wrapper from './ui/components/wrapper/Wrapper';

export default function Error({
  error,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    console.error(error);
  }, [error]);

  return (
    <div className="error-page-container">
      <Wrapper className={`error-page-wrapper`}>
        <div className="error-page">
          <h1 className="error-page__title">Oops, something went wrong</h1>
          <p className="error-page__desc">
            The server encountered an internal error or misconfiguration and was unable to complete
            your request.
          </p>
          <Button
            className="error-page__link"
            buttonUrl="/"
            action="interactive"
            ariaLabel="Back To Home Page"
            color="primary-dark">
            Back To Home Page
          </Button>
        </div>
      </Wrapper>
    </div>
  );
}
